import { MDBCol, MDBInput, MDBRow, MDBTypography } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import {
  ContainedBoxMetric,
  ContainedBoxMetricKeys,
} from '../../../domain/BackEnd.model';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { Container } from 'react-bootstrap';

export const InnerBoxDesignMetric: React.FC<{
  formGroupName: string;
  headingText: string;
  initialValues?: ContainedBoxMetric;
  onChangeValues: (newValues: Partial<ContainedBoxMetric>) => void;
}> = ({ formGroupName, headingText, initialValues, onChangeValues }) => {
  const [formValues, setFormValues] = useState<Partial<ContainedBoxMetric>>(
    initialValues || {}
  );
  const [expand, setExpand] = useState(false);
  const onValueChange = (
    key: ContainedBoxMetricKeys,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newValues = {
      ...formValues,
      [key]: event.target.value,
    };
    setFormValues(newValues);
    onChangeValues(newValues);
  };

  return (
    <>
      <MDBRow>
        <MDBCol>
          <MDBTypography tag="h2">
            {headingText}{' '}
            {!expand ? (
              <BsChevronRight onClick={() => setExpand(!expand)} />
            ) : (
              <BsChevronDown onClick={() => setExpand(!expand)} />
            )}
          </MDBTypography>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4" hidden={!expand}>
        <MDBCol xl={12}>
          <Container>
            <MDBRow className="mb-4">
              <MDBTypography tag="h3">Position</MDBTypography>
            </MDBRow>
            <MDBRow className="mb-4">
              <MDBCol xl={6}>
                <MDBInput
                  name={`${formGroupName}.y`}
                  className="mb-4"
                  required
                  label="Top"
                  type={'number'}
                  min="0"
                  defaultValue={formValues.y}
                  step={0.1}
                  onChange={event => onValueChange('y', event)}
                />
              </MDBCol>
              <MDBCol xl={6}>
                <MDBInput
                  required
                  className="mb-4"
                  name={`${formGroupName}.x`}
                  label="Left"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.x}
                  onChange={event => onValueChange('x', event)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-4">
              <MDBCol xl={12}>
                <MDBTypography tag="h3">Size</MDBTypography>
              </MDBCol>
              <MDBCol>
                <MDBInput
                  required
                  name={`${formGroupName}.width`}
                  label="Width"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.width}
                  onChange={event => onValueChange('width', event)}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  required
                  name={`${formGroupName}.height`}
                  label="Height"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.height}
                  onChange={event => onValueChange('height', event)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-4">
              <MDBCol xl={12}>
                <MDBTypography tag="h3">Border radius</MDBTypography>
              </MDBCol>
              <MDBCol xl={6} className="mb-4">
                <MDBInput
                  name={`${formGroupName}.borderTopLeftRadius`}
                  required
                  label="Top left"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.borderTopLeftRadius}
                  onChange={event =>
                    onValueChange('borderTopLeftRadius', event)
                  }
                />
              </MDBCol>
              <MDBCol xl={6} className="mb-4">
                <MDBInput
                  required
                  name={`${formGroupName}.borderTopRightRadius`}
                  label="Top right"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.borderTopRightRadius}
                  onChange={event =>
                    onValueChange('borderTopRightRadius', event)
                  }
                />
              </MDBCol>
              <MDBCol xl={6} className="mb-4">
                <MDBInput
                  required
                  name={`${formGroupName}.borderBottomRightRadius`}
                  label="Bottom right"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.borderBottomRightRadius}
                  onChange={event =>
                    onValueChange('borderBottomRightRadius', event)
                  }
                />
              </MDBCol>
              <MDBCol xl={6} className="mb-4">
                <MDBInput
                  required
                  name={`${formGroupName}.borderBottomLeftRadius`}
                  label="Bottom left"
                  type={'number'}
                  min="0"
                  step={0.1}
                  defaultValue={formValues.borderBottomLeftRadius}
                  onChange={event =>
                    onValueChange('borderBottomLeftRadius', event)
                  }
                />
              </MDBCol>
            </MDBRow>
          </Container>
        </MDBCol>
      </MDBRow>
    </>
  );
};
