import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CONTAINED_BOX_METRIC } from '../../domain/constants/defaultValues';
import {
  ContainedBoxMetric as ContainedBoxMetric,
  PhoneCase,
} from '../../domain/BackEnd.model';
import { HttpRequest } from '../../domain/HttpRequest.model';

export interface AddPhoneCaseState {
  addingPhoneCase: Partial<PhoneCase>;
  renderRatio: number;
  addPhoneCaseApiCall: HttpRequest<PhoneCase, any>;
}

const initialState: AddPhoneCaseState = {
  addingPhoneCase: {
    imageUrl: '',
    alias: '',
    camera: { ...DEFAULT_CONTAINED_BOX_METRIC },
    exactBoundary: { ...DEFAULT_CONTAINED_BOX_METRIC },
    name: '',
  },
  renderRatio: 1,
  addPhoneCaseApiCall: {
    data: null,
    error: null,
    loading: false,
  },
};

export const addPhoneCaseSlice = createSlice({
  name: 'addPhoneCase',
  initialState,
  reducers: {
    changePhoneCaseImageUrl: (state, action: PayloadAction<string>) => {
      state.addingPhoneCase.imageUrl = action.payload;
    },
    changeExactBoundaryValues: (
      state,
      action: PayloadAction<Partial<ContainedBoxMetric>>
    ) => {
      state.addingPhoneCase.exactBoundary = {
        ...DEFAULT_CONTAINED_BOX_METRIC,
        ...action.payload,
      };
    },
    changeCameraValues: (
      state,
      action: PayloadAction<Partial<ContainedBoxMetric>>
    ) => {
      state.addingPhoneCase.camera = {
        ...DEFAULT_CONTAINED_BOX_METRIC,
        ...action.payload,
      };
    },
    changeRenderRatio: (state, action: PayloadAction<number>) => {
      state.renderRatio = action.payload;
    },
    addPhoneCase: (state, action: PayloadAction<Partial<PhoneCase>>) => {
      state.addPhoneCaseApiCall.loading = true;
    },
    addPhoneCaseSuccess: (state, action: PayloadAction<PhoneCase>) => {
      state.addPhoneCaseApiCall.data = action.payload;
      state.addPhoneCaseApiCall.loading = false;
    },
    addPhoneCaseFailed: (state, action: PayloadAction<any>) => {
      state.addPhoneCaseApiCall.error = action.payload;
      state.addPhoneCaseApiCall.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changePhoneCaseImageUrl,
  changeExactBoundaryValues,
  changeRenderRatio,
  changeCameraValues,
  addPhoneCase,
  addPhoneCaseSuccess,
  addPhoneCaseFailed,
} = addPhoneCaseSlice.actions;

export const { reducer: addPhoneCaseReducer } = addPhoneCaseSlice;
