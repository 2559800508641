import React, { useEffect } from 'react';
import './App.scss';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ProductDetail } from './view/pages/ProductDetail';
import { PhoneCasesPage as PhoneCasesPage } from './view/pages/PhoneCasesPage';
import AppHeader from './view/_components/AppHeader';
import AppFooter from './view/_components/Footer';
import { AddPhoneCase } from './view/pages/AddPhoneCase/AddPhoneCase';

export const App: React.FC = () => {
  useEffect(() => {
    console.log('App init');
  }, []);
  return (
    <>
      <AppHeader />
      <Router>
        <Switch>
          <Route exact path="/">
            <PhoneCasesPage />
          </Route>
          <Route exact path="/phone-cases/add">
            <AddPhoneCase />
          </Route>
          <Route exact path="/phone-cases/:productName">
            <ProductDetail />
          </Route>
        </Switch>
      </Router>
      <AppFooter />
    </>
  );
};
