import { ContainedBoxMetric } from '../BackEnd.model';

export const DEFAULT_CONTAINED_BOX_METRIC: ContainedBoxMetric = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
};
