import axios from 'axios';
import { BASE_API_URL } from '../../domain/constants/api';
import { PhoneCase } from '../../domain/BackEnd.model';

function addPhoneCase(phoneCaseData: PhoneCase) {
  return axios
    .post<any>(`${BASE_API_URL}/phone-cases`, phoneCaseData)
    .then(res => res.data);
}

export { addPhoneCase };
