import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchPhoneCases } from '../../application/ListingPage/ListingPage.slice';
import { RootState } from '../../application/root.reducer';
import { PhoneCase } from '../../domain/BackEnd.model';
import { HttpRequest } from '../../domain/HttpRequest.model';
import AppBanner from '../_components/AppBanner';
import { ErrorMessage } from '../_components/ErrorMessage';
import './PhoneCasesPage.scss';

export const PhoneCasesPage: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector<
    RootState,
    HttpRequest<PhoneCase[], any>
  >(state => state.listingPage.apiCall);
  const { push } = useHistory();
  useEffect(() => {
    dispatch(fetchPhoneCases());
  }, []);

  const onProductClick = (product: PhoneCase) => {
    push(`productDetails/${product.name}`);
  };

  const onAddNewPhoneCaseClicked = () => {
    push('phone-cases/add');
  };

  if (loading) {
    return <>Loading</>;
  }
  if (error) {
    return (
      <ErrorMessage
        message={'Loading phone case failed'}
        resolve={'Try again'}
      />
    );
  }
  if (!data?.length) {
    return (
      <Container>
        <Row>
          <Button variant="primary" onClick={onAddNewPhoneCaseClicked}>
            Add new phone case
          </Button>
        </Row>
      </Container>
    );
  }
  return (
    <>
      <AppBanner title={'Phone cases'} subtitle={'Availabe phone cases'} />
      <Container className="pb-4 pt-4">
        <Row className="mb-4">
          <Col
            lg={{
              span: 4,
              offset: 8,
            }}
          >
            <Button variant="primary" onClick={onAddNewPhoneCaseClicked}>
              Add new phone case
            </Button>
          </Col>
        </Row>
        <Row>
          {data?.map(product => {
            return (
              <Col key={product.id} xl="3" className="mb-2">
                <Card>
                  <div
                    style={{
                      maxHeight: '300px',
                      width: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    <Card.Img
                      bsPrefix="listingItem__image"
                      height="300"
                      variant="top"
                      src={product.imageUrl}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>{product.name}</Card.Title>
                    <Card.Text>A good phone case for customize.</Card.Text>
                    <Button onClick={() => onProductClick(product)}>
                      Go to detail
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};
