import { ListingPageState } from '@application/ListingPage/ListingPage.slice';
import { RootState } from '@application/root.reducer';
import { OutputPhoneCasePreview } from '@qcases/common-ui';
import React, { useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const ProductDetail: React.FC = () => {
  const { apiCall } = useSelector<RootState, ListingPageState>(
    state => state.listingPage
  );
  const [backgroundImageRatio, setBackgroundImageRatio] = useState(1);
  const onChangeOutputRenderRatio = (newRatio: number) => {
    setBackgroundImageRatio(backgroundImageRatio);
  };
  const phoneCase = apiCall?.data?.find((p, i) => i === 0);
  // TODO: just add for passing type check, remove after refactor qcases common ui
  const ref = useRef(null);
  return (
    <Container className="productDetail">
      <Row className="productDetail__infoArea">
        {!phoneCase ? null : (
          <OutputPhoneCasePreview
            phoneCase={phoneCase!}
            ratio={backgroundImageRatio}
            exactBoundaryRef={ref}
            onChangeRenderRatio={onChangeOutputRenderRatio}
          />
        )}
      </Row>
    </Container>
  );
};
