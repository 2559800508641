import { MDBCol, MDBInput, MDBRow, MDBTypography } from 'mdb-react-ui-kit';
import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePhoneCaseImageUrl,
  changeCameraValues,
  changeExactBoundaryValues,
  addPhoneCase,
  AddPhoneCaseState,
  changeRenderRatio,
} from '../../../application/AddPhoneCase/AddPhoneCase.slice';
import { RootState } from '../../../application/root.reducer';
import { ContainedBoxMetric, PhoneCase } from '../../../domain/BackEnd.model';
import { InnerBoxDesignMetric } from './InnerBoxDesignMetric';
import {
  buildFormData,
  OutputPhoneCasePreview,
  CustomButton,
} from '@qcases/common-ui';
import './AddPhoneCase.scss';

export const AddPhoneCase: React.FC = () => {
  const [phoneCaseAlias, setPhoneCaseAlias] = useState('');
  const {
    addPhoneCaseApiCall,
    addingPhoneCase,
    renderRatio: backgroundImageRatio,
  } = useSelector<RootState, AddPhoneCaseState>(state => state.addPhoneCase);
  const dispatch = useDispatch();
  const onPhoneCaseNameChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    if (!value) {
      setPhoneCaseAlias('');
      return;
    }
    setPhoneCaseAlias(value.toLowerCase().split(' ').join('-'));
  };

  const onPhoneCaseImageUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    if (!value) {
      dispatch(changePhoneCaseImageUrl(''));
      return;
    }
    dispatch(changePhoneCaseImageUrl(value));
  };

  const onExactBoundaryChangeValues = (
    newValues: Partial<ContainedBoxMetric>
  ) => {
    dispatch(changeExactBoundaryValues(newValues));
  };

  const onCameraChangeValues = (newValues: Partial<ContainedBoxMetric>) => {
    dispatch(changeCameraValues(newValues));
  };

  const onFormSubmit = (event: any) => {
    event.preventDefault();
    const formData = buildFormData<Partial<PhoneCase>>(event.target);
    dispatch(addPhoneCase(formData));
  };

  const onChangeOutputRenderRatio = (newRatio: number) => {
    dispatch(changeRenderRatio(newRatio));
  };

  const ref = useRef(null);
  return (
    <Container className="addPhoneCase pt-4 pb-4">
      <Row className="addPhoneCase__basicInfo">
        <Col xl={6}>
          <form onSubmit={onFormSubmit} method="post">
            <MDBRow>
              <Col>
                <MDBTypography tag="h2">Basic info</MDBTypography>
              </Col>
            </MDBRow>
            <MDBRow className="mb-4">
              <MDBCol>
                <MDBInput
                  required
                  label="Phone case name"
                  name="name"
                  onChange={onPhoneCaseNameChanged}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  label="Phone case alias"
                  name="alias"
                  value={phoneCaseAlias}
                  readOnly
                />
              </MDBCol>
            </MDBRow>
            <MDBInput
              className="mb-4"
              label="Phone case image url"
              name="imageUrl"
              onChange={onPhoneCaseImageUrlChange}
            />
            <InnerBoxDesignMetric
              headingText="Exact boundary"
              formGroupName="exactBoundary"
              onChangeValues={onExactBoundaryChangeValues}
            />
            <InnerBoxDesignMetric
              headingText="Camera"
              formGroupName="camera"
              onChangeValues={onCameraChangeValues}
            />
            <CustomButton type="submit" loading={addPhoneCaseApiCall.loading}>
              Add <BsPlusCircle />
            </CustomButton>
          </form>
        </Col>
        <Col xl={6}>
          <OutputPhoneCasePreview
            exactBoundaryRef={ref}
            phoneCase={addingPhoneCase}
            ratio={backgroundImageRatio}
            onChangeRenderRatio={onChangeOutputRenderRatio}
          />
        </Col>
      </Row>
    </Container>
  );
};
