import { call, put, takeLatest } from 'redux-saga/effects';
import { addPhoneCase } from './AddPhoneCase.service';
import {
  addPhoneCase as addPhoneCaseAction,
  addPhoneCaseFailed,
  addPhoneCaseSuccess,
} from './AddPhoneCase.slice';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* addPhoneCaseAsync(action) {
  try {
    const phoneCases = yield call(addPhoneCase, action.payload);
    yield put(addPhoneCaseSuccess(phoneCases));
  } catch (e) {
    yield put(addPhoneCaseFailed((e as unknown as any).message as string));
  }
}

export function* addPhoneCaseSaga() {
  yield takeLatest(addPhoneCaseAction.type, addPhoneCaseAsync);
}
