import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchPhoneCases as fetchPhoneCasesAction,
  fetchPhoneCasesFailed,
  fetchPhoneCasesSuccess,
} from './ListingPage.slice';
import { getPhoneCases } from './ListingPageApi.service';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchPhoneCases(action) {
  try {
    const phoneCases = yield call(getPhoneCases);
    yield put(fetchPhoneCasesSuccess(phoneCases));
  } catch (e) {
    yield put(fetchPhoneCasesFailed((e as unknown as any).message as string));
  }
}

export function* listingPageSaga() {
  yield takeLatest(fetchPhoneCasesAction.type, fetchPhoneCases);
}
