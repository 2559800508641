import { combineReducers } from 'redux';
import { addPhoneCaseReducer } from './AddPhoneCase/AddPhoneCase.slice';
import { listingPageReducer } from './ListingPage/ListingPage.slice';

export const rootReducer = combineReducers({
  listingPage: listingPageReducer,
  addPhoneCase: addPhoneCaseReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
