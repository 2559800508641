import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './App.store';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { ErrorBoundary } from './view/_components/ErrorBoundary';

const rootId = 'root';
const rootElement = document.createElement('div');
rootElement.setAttribute('id', rootId);
document.body.append(rootElement);

const root = ReactDOM.createRoot(
  document.getElementById(rootId) as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
